import { createStore } from "vuex"
import { Api } from "@/services"

const api = Api.create()

export const store = createStore({
    state: {
        isSignIn: false,
        splData: []
    },
    mutations: {
        IS_SIGN_IN(state, value) {
            state.isSignIn = value
        },
        SPL_DATA(state, value) {
            state.splData = value
        }
    },
    actions: {
        isSignIn({ commit }, payload) {
            commit("IS_SIGN_IN", payload)
        },
        splData({ commit }) {
            api.getExampleData()
                .then(res => {
                    if (res.data.Success) {
                        commit("SPL_DATA", res.data.Data)
                    }
                })
                .catch(error => {
                    console.log("PAT_DEV", error)
                })
        }
    },
    getters: {
        isSignIn: state => {
            return state.isSignIn
        },
        splData: state => {
            return state.splData
        }
    },
    modules: {}
})

export default { store }
