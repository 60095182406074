<template>
    <div class="flex flex-row">
        <router-view />
    </div>
</template>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}
</style>
<script>
import { getSavedLocale } from "@/util/locate"
import { getDefaultLocale, isSupportedLocale } from "@/util/locate"
import { computed } from "@vue/reactivity"
import { useRoute, useRouter } from "vue-router"

export default {
    watch: {
        $route(to, from, next) {
            document.title = `${to.meta.title}`
            // const link = document.querySelector("[rel='icon']")
            // link.setAttribute("href", to.meta.icon)
        }
    },
    mounted() {
        this.setViewHeight()
        window.addEventListener("resize", () => {
            this.setViewHeight()
        })
        if (window.location.pathname !== "/redirect/resetpw") {
            if (!this.$auth.isAuthenticated.value) {
                this.$router.push({
                    name: "Login",
                    params: { lang: getSavedLocale() }
                })
            } else {
                this.$router.push({
                    name: "Dashboard",
                    params: { lang: getSavedLocale() }
                })
            }
        }
        // this.$router.push({
        //     name: "Redirect",
        //     params: { lang: getSavedLocale() }
        // })
    },
    methods: {
        setViewHeight: function() {
            let vh = window.innerHeight * 0.01
            document.documentElement.style.setProperty("--vh", `${vh}px`)
        }
    }
}
</script>
