import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import Auth from "@/auth"
import { store } from "@/store"
import i18n from "@/plugins/i18n"
import "./index.css"
import axios from "axios"

async function init() {
    const AuthPlugin = await Auth.init({
        onRedirectCallback: appState => {
            router.push(
                appState && appState.targetUrl
                    ? appState.targetUrl
                    : window.location.pathname
            )
        }
    })

    const app = createApp(App)

    const rootComponent = app
        .use(AuthPlugin)
        .use(store)
        .use(router)
        .use(i18n)
        .mount("#app")

    app.config.globalProperties.$axios = axios
}

init()
