import { createRouter, createWebHistory } from "vue-router"

import Auth from "@/auth"
// import Home from "@/views/Home.vue"
// import PathNotFound from "@/views/error/PageNotFound"
// import Login from "@/views/Login"
// import Dashboard from "@/views/Dashboard"
// import Approvals from "@/views/Approvals"
// import Topup from "@/views/Topup"
// import Bookings from "@/views/Bookings"
// import Users from "@/views/Users"
// import TransactionRecords from "@/views/TransactionRecords"
// import Setting from "@/views/Setting"
// import Support from "@/views/Support"
// import TransactionApprovals from "@/views/TransactionApprovals"
// import Redirect from "@/views/Redirect"

import {
    getSavedLocale,
    setCurrentLocale,
    getDefaultLocale,
    isSupportedLocale
} from "@/util/locate"

const routes = [
    {
        path: "/:pathMatch(.*)*",
        name: "PathNotFound",
        component: () =>
            import(/* webpackChunkName: "404" */ "@/views/error/PageNotFound")
    },
    {
        path: "/redirect/resetpw",
        name: "Redirect",
        component: () =>
            import(/* webpackChunkName: "redirect" */ "@/views/Redirect"),
        meta: {
            title: "Redirect"
        }
    },
    {
        path: "/:lang/login",
        name: "Login",
        component: () =>
            import(/* webpackChunkName: "login" */ "@/views/Login"),
        meta: {
            title: "Login"
        }
    },
    {
        path: "/:lang/user",
        component: () => import(/* webpackChunkName: "home" */ "@/views/Home"),
        beforeEnter: Auth.routeGuard,
        children: [
            {
                path: "dashboard",
                name: "Dashboard",
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/Dashboard"
                    ),
                meta: {
                    title: "Dashboard"
                }
            },
            // {
            //     path: "approvals",
            //     name: "Approvals",
            //     meta: {
            //         title: "Approvals"
            //     },
            //     // route level code-splitting
            //     // this generates a separate chunk (about.[hash].js) for this route
            //     // which is lazy-loaded when the route is visited.
            //     component: () =>
            //         import(
            //             /* webpackChunkName: "about" */ "../views/Approvals.vue"
            //         )
            // },
            {
                path: "topup",
                name: "TopUp",
                component: () =>
                    import(/* webpackChunkName: "billing" */ "@/views/Topup"),
                meta: {
                    title: "Billing"
                }
            },
            {
                path: "bookings",
                name: "Bookings",
                component: () =>
                    import(
                        /* webpackChunkName: "bookings" */ "@/views/Bookings"
                    ),
                meta: {
                    title: "Bookings"
                },
                props: true
            },
            {
                path: "users",
                name: "Users",
                component: () =>
                    import(/* webpackChunkName: "users" */ "@/views/Users"),
                meta: {
                    title: "Users & Limits"
                }
            },
            {
                path: "paymentrecords",
                name: "PaymentRecords",
                component: () =>
                    import(
                        /* webpackChunkName: "transactionrecords" */ "@/views/TransactionRecords"
                    ),
                meta: {
                    title: "Billing"
                }
            },
            {
                path: "setting",
                name: "Setting",
                component: () =>
                    import(/* webpackChunkName: "setting" */ "@/views/Setting"),
                meta: {
                    title: "Setting"
                }
            },
            {
                path: "support",
                name: "Support",
                component: () =>
                    import(/* webpackChunkName: "support" */ "@/views/Support"),
                meta: {
                    title: "Support"
                }
            },
            {
                path: "topup_approvals",
                name: "Topup_Approvals",
                component: () =>
                    import(
                        /* webpackChunkName: "transactionapprovals" */ "@/views/TransactionApprovals"
                    ),
                meta: {
                    title: "Top up approval"
                }
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach(async (to, from, next) => {
    if (to.path !== "/redirect/resetpw") {
        const savedLang = getSavedLocale()
        const urlLang = to.path.split("/")[1]
        if (isSupportedLocale(urlLang)) {
            setCurrentLocale(urlLang)
            next()
        } else {
            const path = to.path.slice(to.path.indexOf("/", 1))
            let translatedPath = "/" + getDefaultLocale() + path
            if (isSupportedLocale(savedLang)) {
                translatedPath = "/" + savedLang + path
            }
            return next({ path: translatedPath })
        }
    } else {
        next()
    }
})

export default router
