import { Storage } from "@/constants"
import { Locale } from "@/constants"
import i18n from "@/plugins/i18n"

export function getSavedLocale() {
    return localStorage.getItem(Storage.STORAGE_LOCALE_KEY)
}

export function setSavedLocale(lang) {
    return localStorage.setItem(Storage.STORAGE_LOCALE_KEY, lang)
}

export function getCurrentLocale() {
    return i18n.global.locale
}

export function setCurrentLocale(lang) {
    i18n.global.locale = lang
}

export function getDefaultLocale() {
    return i18n.global.fallbackLocale
}

export function isSupportedLocale(lang) {
    return Locale.SUPPORTED_LOCALES.some(function(locale) {
        return locale.code === lang
    })
}

export default {
    getSavedLocale,
    setSavedLocale,
    getCurrentLocale,
    setCurrentLocale,
    getDefaultLocale,
    isSupportedLocale
}
