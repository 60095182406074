import axios from "axios"
import { getCurrentLocale } from "@/util/locate"

// const BASE_URL = process.env.API_BASE_URL
// const BASE_URL = "https://api.flowhk.app" + "/api/web/v3"

const create = () => {
    const api = axios.create({
        // base URL is read from the "constructor"
        // baseURL,
        // here are some default headers
        headers: {
            // "Cache-Control": "no-cache",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Credentials": true
        },
        // 30 second timeout
        timeout: 30000
    })

    api.interceptors.request.use(config => {
        config.lang = getCurrentLocale()
        return config
    })

    const getExampleData = () => {
        const url = "/index/space_type"
        let params = {
            lang: getCurrentLocale()
        }
        return api.get(url, { params })
    }

    return {
        getExampleData
    }
}

export default {
    create
}
